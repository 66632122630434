import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesRapport = _resolveComponent("SalesRapport")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_RepportTicketIncome = _resolveComponent("RepportTicketIncome")!
  const _component_ProductMovementsRapport = _resolveComponent("ProductMovementsRapport")!
  const _component_ExpensesRapport = _resolveComponent("ExpensesRapport")!
  const _component_ComparisonRapport = _resolveComponent("ComparisonRapport")!
  const _component_ProductStockRapport = _resolveComponent("ProductStockRapport")!
  const _component_StockMovementsRapport = _resolveComponent("StockMovementsRapport")!
  const _component_SequencesRapport = _resolveComponent("SequencesRapport")!
  const _component_StocksExpirations = _resolveComponent("StocksExpirations")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, {
    lazy: "",
    "active-index": _ctx.active,
    "onUpdate:activeIndex": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      _ctx.saveActive
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.sales')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SalesRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.saleWithIncome')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_RepportTicketIncome)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.soldProducts')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ProductMovementsRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.expenses')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ExpensesRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.comparison')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ComparisonRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.productStock')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ProductStockRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('rapport.movement')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_StockMovementsRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('pos.sequence')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SequencesRapport)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('stock.expiration')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_StocksExpirations)
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["active-index", "onUpdate:activeIndex"]))
}